import httpService from '../../../helpers/http.service'

export default {
    get(id = null, params = {}) {
        return httpService.get(`processed/enrollments/commissions${id ? '/' + id : '' }`, {params})
    },
    delete(id) {
        return httpService.delete(`processed/enrollments/commissions/${id}`);
    },
    save(params, id = null) {
        if(id) {
            return httpService.put(`processed/enrollments/commissions/${id}`, params);

        }
        return httpService.post(`processed/enrollments`, params);
    },
    generate(id) {
        return httpService.post(`processed/enrollments/${id}/commissions`);
    },
    getPosition(planId){
        return httpService.get(`plans/${planId}/commissions/scales/select`);
    },
    getNames(positionId){
        return httpService.get(`employees/positions/${positionId}`);
    },
    addRow(cId, params){
        return httpService.post(`processed/enrollments/commissions/add/${cId}`, params);
    },
    getEmployees(query){
        return httpService.get(`employees/select?q=${query}&active=1`);
    },
}
