<template>

  <div v-if="canAccess('contralor_actions')">
    <BlockUI :blocked="loading" :fullScreen="true" style="position:absolute;top:50%;left:50%;">
      <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
    </BlockUI>
    <div v-if="viewForm">
      <div class="p-grid">
        <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" class="p-col-12 p-breadcrumb p-component"/>
        <Fieldset legend="Detalle" :toggleable="false" class="p-col-12">
          <div class="p-grid">
            <div class="p-col-12">
              <div class="hide">
                {{ fecha = liquidationData.head?.generate_at }}
                {{ id = liquidationData.head?.id }}
                {{mes = liquidationData.head?.liquidation_month?.month_process}}
                {{semana = liquidationData.head?.liquidation_week?.week_process}}
                {{ nombre = liquidationData.head?.employee?.name }}
                {{ orden = liquidationData.head?.payment_order }}
                {{ fechaliq = liquidationData.head?.generate_at }}
                {{ cargo = liquidationData.head?.employee?.position?.description }}
                {{ filial = liquidationData.head?.employee?.subsidiary?.name }}
                {{ cc = liquidationData.head?.employee?.dni }}
              </div>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="fecha" class="p-inputtext-sm p-col-12" :disabled="true"/>
                            <label for="">Fecha</label>
                        </span>
              </div>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="id" class="p-inputtext-sm p-col-12" :disabled="true"/>
                            <label for="">Cons. No</label>
                        </span>
              </div>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="mes" class="p-inputtext-sm p-col-12" :disabled="true"/>
                            <label for="">Mes Liquidación</label>
                        </span>
              </div>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="semana" class="p-inputtext-sm p-col-12" :disabled="true"/>
                            <label for="">Semana Liquidación</label>
                        </span>
              </div>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="nombre" class="p-inputtext-sm p-col-12" :disabled="true"/>
                            <label for="">Nombre de asesor</label>
                        </span>
              </div>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="orden" class="p-inputtext-sm p-col-12" :disabled="true"/>
                            <label for="">Orden de pago</label>
                        </span>
              </div>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="fechaliq" class="p-inputtext-sm p-col-12" :disabled="true"/>
                            <label for="">Fecha de liquidación</label>
                        </span>
              </div>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="cargo" class="p-inputtext-sm p-col-12" :disabled="true"/>
                            <label for="">Cargo</label>
                        </span>
              </div>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="filial" class="p-inputtext-sm p-col-12" :disabled="true"/>
                            <label for="">Filial</label>
                        </span>
              </div>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="cc" class="p-inputtext-sm p-col-12" :disabled="true"/>
                            <label for="">C.C</label>
                        </span>
              </div>
            </div>
          </div>
        </Fieldset>
        <Fieldset legend="Comisiones" :toggleable="false" class="p-unique">
          <ProgressBar mode="indeterminate" v-if="loading"/>
          <DataTable :value="items" :key="items"
                     dataKey="id" v-model:selection="currentItem" selectionMode="single"
                     @row-select="selectData()">
            <template #header>
              <Toolbar class="p-col-12 p-mb-1">
                <template #left>
                  Contratos (Matriculas)
                </template>
                <template #right>
                  Total de Comisiones: {{formatMoney( liquidationData.total_body) }}
                </template>
              </Toolbar>
            </template>

            <!-- <template v-if="fTR !== 1"> -->
            <Column headerStyle="width: 50px">
              <template #body="slotProps">
                <i class="pi pi-info-circle" style="color: #2196F3"
                   @click="openContract(slotProps.data.process_enrollment)"/>
              </template>
            </Column>
            <Column headerStyle="width: 50px">
              <template #body="slotProps">
                <i class="pi pi-info-circle" style="color: #689F38"
                   @click="openCommissions(slotProps.data.process_enrollment)"/>
              </template>
            </Column>
            <Column field="contract" header="MATRICULA" :sortable="true"></Column>
            <Column field="contract" header="MATRICULA" :sortable="true"></Column>
            <Column field="student_last_name" header="APELLIDOS" :sortable="true"></Column>
            <Column field="student_name" header="NOMBRES" :sortable="true"></Column>
            <Column field="plan" header="P.BASICO" :sortable="true"></Column>
            <Column field="sum_commission_value" header="VALOR" :sortable="true">
              <template #body="slotProps">
                  {{formatMoney(slotProps.data.sum_commission_value)}}
              </template>
            </Column>
          </DataTable>
          <br/>

          <ProgressBar mode="indeterminate" v-if="loading"/>
          <DataTable class="t-margin" :value="items2" :key="items2">
            <template #header>
              <Toolbar class="p-col-12 p-mb-1">
                <template #left v-if="currentItem">
                  Comisiones por Matricula Seleccionada: {{ currentItem.contract }}
                </template>
              </Toolbar>
            </template>
            <Column field="student_name" header="ALUMNO" :sortable="true"></Column>
            <Column field="contract" header="MATRICULA" :sortable="true"></Column>
            <Column field="plan" header="P.BASICO" :sortable="true"></Column>
            <Column field="enrollment_value" header="Vr.MATRICULA" :sortable="true">
              <template #body="slotProps">
                {{formatMoney(slotProps.data.enrollment_value)}}
              </template>
            </Column>
            <Column field="position" header="CARGO" :sortable="true">
              <template #body="slotProps">
                {{slotProps.data.position}}
              </template>
            </Column>
            <Column field="commission_value" header="VALOR" :sortable="true">
              <template #body="slotProps">
                {{formatMoney(slotProps.data.commission_value)}}
              </template>
            </Column>
          </DataTable>
        </Fieldset>
        <Fieldset legend="Liquidación" :toggleable="false" class="p-unique">
          <ProgressBar mode="indeterminate" v-if="loading"/>
          <DataTable :value="items3" :key="items3">
            <template #header>
              <Toolbar class="p-col-12 p-mb-1">
                <template #left>
                  Liquidación
                </template>
              </Toolbar>
            </template>

            <Column field="code" header="CODIGO" :sortable="true"></Column>
            <Column field="code_description" header="DESCRIPCIÓN" :sortable="true"></Column>
            <Column field="value" header="DEBITOS" :sortable="true">
              <template #body="slotProps">

                <div class="center" v-if="slotProps.data.is_debit">
                  {{formatMoney(slotProps.data.value)}}
                </div>
                <div class="center" v-else> {{formatMoney(0)}}</div>
              </template>
            </Column>
            <Column header="CREDITOS" :sortable="true">
              <template #body="slotProps">
                <div class="center" v-if="slotProps.data.is_credit">
                  {{formatMoney(slotProps.data.value)}}
                </div>
                <div class="center" v-else> {{formatMoney(0)}}</div>
              </template>
            </Column>
            <Column field="description" header="OBSERVACIONES" :sortable="true"></Column>
          </DataTable>
        </Fieldset>
        <Fieldset legend="Otros valores" :toggleable="false" class="p-unique">
          <ProgressBar mode="indeterminate" v-if="loading"/>
          <DataTable :value="items4" :key="items4" class="table-fix">
            <template #header>
              <Toolbar class="p-col-12 p-mb-1">
                <template #left>
                  Otros valores
                </template>
              </Toolbar>
            </template>

            <Column headerStyle="width: 15%" field="code" header="CONCEPTO" :sortable="true">
              <template #body="slotProps">
                <!-- <Dropdown :disabled="disable" v-model="listaSelOtro"
                :options="listaOtros"
                optionLabel="name"
                :placeholder="slotProps.data.code_description"
                /> -->
                {{ slotProps.data.code_description }}
              </template>
            </Column>
            <Column headerStyle="width: 15%" field="enrollment" header="MATRICULA No" :sortable="true">
              <template #body="slotProps">
                <div class="center">
                  {{ slotProps.data.enrollment }}
                  <!-- <InputText :disabled="disable" v-model="inputOthers" :placeholder="slotProps.data.enrollment" @keydown.enter="updateDC(slotProps.data.id)"/> -->
                </div>
              </template>
            </Column>
            <Column headerStyle="width: 15%" field="debits" header="DEBITOS" :sortable="true">
              <template #body="slotProps">
                <div class="center">
                  {{formatMoney(slotProps.data.debits )}}
                 <!-- <InputText :disabled="disable" v-model="inputOthers" :placeholder="slotProps.data.debits" @keydown.enter="updateDC(slotProps.data.id)"/> -->
                </div>
              </template>
            </Column>
            <Column headerStyle="width: 15%" field="credits" header="CREDITOS" :sortable="true">
              <template #body="slotProps">
                <div class="center">
                  {{formatMoney(slotProps.data.credits )}}
                 </div>
              </template>
            </Column>
            <Column headerStyle="width: 15%" field="description" header="OBSERVACIONES" :sortable="true">
              <template #body="slotProps">
                <div class="center">
                  {{ slotProps.data.description }}
                  <!-- <InputText :disabled="disable" v-model="inputOthers" :placeholder="slotProps.data.description" @keydown.enter="updateDC(slotProps.data.id)"/> -->
                </div>
              </template>
            </Column>
            <Column headerStyle="width: 15%" header="" field="">
            </Column>
          </DataTable>
        </Fieldset>
        <Fieldset legend="Prestamos" :toggleable="false" class="p-unique">
          <ProgressBar mode="indeterminate" v-if="loading"/>
          <DataTable :value="items5" :key="items5" class="table-fix">
            <template #header>
              <Toolbar class="p-col-12 p-mb-1">
                <template #left>
                  Prestamos
                </template>
              </Toolbar>
            </template>
            <Column headerStyle="width: 15%" field="code" header="CONCEPTO" :sortable="true">
              <template #body="slotProps">
                <!-- <Dropdown v-model="listaSelConcepto"
                :options="listaConceptos"
                optionLabel="name"
                :placeholder="slotProps.data.code"
                /> -->
                {{ slotProps.data.code }}
              </template>
            </Column>
            <Column headerStyle="width: 15%" field="value_loan" header="Valor Prestamo" :sortable="true">
              <template #body="slotProps">
                <div class="center">
                  {{formatMoney(slotProps.data.value_loan )}}
                </div>
              </template>
            </Column>
            <Column headerStyle="width: 15%" field="date_at" header="Fecha" :sortable="true"></Column>
            <Column headerStyle="width: 15%" field="value_amortize" header="Saldo Ant. Vr. Amortizar"
                    :sortable="true">
              <template #body="slotProps">
                <div class="center">
                  {{formatMoney(slotProps.data.value_amortize )}}
                </div>
              </template>
            </Column>
            <Column headerStyle="width: 15%" field="balance" header="Saldo" :sortable="true">
              <template #body="slotProps">
                <div class="center">
                  {{formatMoney(slotProps.data.balance )}}
                </div>
              </template>
            </Column>
          </DataTable>
        </Fieldset>

        <Dialog v-model:visible="commissionsDialog" :style="{width: '950px'}" header="Información de Comisiones"
                :modal="true">
          <DataTable :value="itemsT" scrollable :key="itemsT" class="table">
            <Column field="position.description" header="ASESOR" :sortable="true"></Column>
            <Column field="employee_with_ancestors.name" header="NOMBRE" :sortable="true"
                    headerStyle="width: 350px"></Column>
            <Column field="cost" header="$ Comisión" :sortable="true">
              <template #body="slotProps">
                {{formatMoney(slotProps.data.cost)}}
              </template>
            </Column>
            <Column field="approved" header="SE PAGA">
              <template #body="slotProps">
                <template v-if="slotProps.data.approved === 'n'">
                  <Checkbox v-model="slotProps.data.approved" :binary="false" disabled="true"/>
                 </template>
                <template v-else-if="slotProps.data.approved === 'y'">
                  <Checkbox v-model="slotProps.data.approved" :binary="slotProps.data.approved" disabled="true"/>
                </template>
              </template>
            </Column>
          </DataTable>
        </Dialog>
        <Dialog v-model:visible="contractDialog" :style="{width: '950px'}" header="Información de Matricula"
                :modal="true">
          <div class="p-grid">
            <div class="p-col-12">
              <div class="hide">
                {{ id = commissionData.enrollment?.contract?.holder?.id }}
                {{
                  nombre = commissionData.enrollment?.contract?.holder?.name + " " + commissionData.enrollment?.contract?.holder?.last_name
                }}
                {{ matricula = commissionData.enrollment?.sequential }}
                {{ plan = commissionData.enrollment?.plan?.name }}
                {{ asesor = commissionData.enrollment?.employee?.name }}
                {{ estado = commissionData.enrollment?.status }}
                {{ filiar = commissionData.enrollment?.subsidiary?.name }}
                {{ valor = commissionData.enrollment?.plan?.cost }}
                {{ mes = commissionData.enrollment?.month_id }}
                {{ semana = commissionData.enrollment?.week?.week }}
                {{ mesP = commissionData.month_post?.month }}
                {{ semanaP = commissionData.week_post?.week }}
                {{ registro = commissionData.enrollment?.registration }}
              </div>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="id" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label>Id Titular</label>
                        </span>
              </div>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="nombre" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label>Nombre de Titular</label>
                        </span>
              </div>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="matricula" class="p-inputtext-sm p-col-12"
                                       :disabled="!edit"/>
                            <label>No Matricula</label>
                        </span>
              </div>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="plan" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label>Plan de Pago</label>
                        </span>
              </div>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="asesor" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label>Nombre de asesor</label>
                        </span>
              </div>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="estado" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label>Estado de Matricula</label>
                        </span>
              </div>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="commissionData.created_at" class="p-inputtext-sm p-col-12"
                                       :disabled="!edit"/>
                            <label>Fecha de Ingreso</label>
                        </span>
              </div>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="filiar" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label>Filial</label>
                        </span>
              </div>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="valor" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label>Valor Plan</label>
                        </span>
              </div>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="registro" class="p-inputtext-sm p-col-12"
                                       :disabled="!edit"/>
                            <label>Fecha de verificación</label>
                        </span>
              </div>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="commissionData.status" class="p-inputtext-sm p-col-12"
                                       :disabled="!edit"/>
                            <label>Estado de Comisiones</label>
                        </span>
              </div>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="mes" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label>Mes</label>
                        </span>
              </div>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="semana" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label>Semana</label>
                        </span>
              </div>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="commissionData.invoice_number"
                                       class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label>No. Factura</label>
                        </span>
              </div>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="commissionData.registry_number"
                                       class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label>No. Registro</label>
                        </span>
              </div>
              <br/>

              <template v-if="commissionData.month_post">
                <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="mesP" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label>Mes Postergado</label>
                        </span>
                </div>
              </template>
              <template v-if="commissionData.week_post">
                <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="semanaP" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label>Semana Postergado</label>
                        </span>
                </div>
              </template>
            </div>
          </div>
        </Dialog>
        <Dialog v-if="canAccess('contralor_actions')" v-model:visible="valDialog" :style="{width: '450px'}"
                header="Confirmar" :modal="true">
          <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
            <span>Esta seguro de validar la liquidación?</span>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text"
                    @click="valDialog = false"/>
            <Button label="Si" icon="pi pi-check" class="p-button-text" @click="changeStatus()"/>
          </template>
        </Dialog>
        <Dialog v-if="canAccess('revert_status_new')" v-model:visible="revertDialog" :style="{width: '450px'}"
                header="Confirmar" :modal="true">
          <div class="confirmation-content">
            <span><b>Esta seguro de reversar la liquidación?</b></span>
            <hr/>
            <textarea placeholder="Motivo para realizar procedimiento"  v-model="observationRevert" class="form-control" rows="5" style="width: 100%;resize: none"/>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text"
                    @click="revertDialog = false"/>
            <Button label="Si" icon="pi pi-check" class="p-button-text" @click="revertStatus()"/>
          </template>
        </Dialog>
        <Dialog v-model:visible="pdf" :style="{width: '1050px'}" header="Resumen de Liquidación" :modal="true">
          <div id="pdfContainer" v-html="info">
          </div>
          <template #footer>
            <Button label="Generar PDF" class="p-button-success" @click="generatePDF"/>
          </template>
        </Dialog>

        <div class="valButtons" v-if="liquidationData.head?.status === 'Aprobada'">
          <Button label="Regresar " icon="pi pi-arrow-left"
                  class="p-button-secondary p-mr-2"
                  @click="actionToList()"/>
          <Button v-if="canAccess('contralor_actions')" label="Validar Contralor" icon="pi pi-plus"
                  class="p-button-success p-mr-2"
                  @click="valDialog = true"/>
          <Button v-if="canAccess('revert_status_new')" label="Reversar" icon="pi pi-minus-circle"
                  class="p-button-outlined p-button-danger p-mr-2"
                  @click="revertDialog = true"/>
        </div>
        <div class="valButtons" v-else-if="liquidationData.head?.status === 'Validada'">
          <Button label="Regresar " icon="pi pi-arrow-left"
                  class="p-button-secondary p-mr-2"
                  @click="actionToList()"/>
          <Button label="Ver PDF" class="p-button-info p-mr-2" @click="getPdf"/>
        </div>
        <template v-if="liquidationData">
          <div class="p-grid p-fieldset full" v-if="liquidationData.accumulate_commissions">
            <div class="p-col">
              <div class="p-grid">
                <div class="p-col  inside">
                  <div class="tTitle">Acumulado Anterior:</div>
                  <div class="tTitle">Presente Semana:</div>
                  <div class="tTitle">Acumula. Semana:</div>
                </div>
                <div class="p-col-fixed inside" style="width:33%">
                  <div class="tContent">{{ formatMoney(liquidationData.accumulate_commissions.old) }}</div>
                  <div class="tContent">{{ formatMoney(liquidationData.accumulate_commissions.present) }}</div>
                  <div class="tContent">{{ formatMoney(liquidationData.accumulate_commissions.total) }}</div>
                </div>
              </div>
            </div>

            <div class="p-col">
              <div class="p-grid">
                <div class="p-col inside">
                  <div class="tTitle">Reserva Acumulada:</div>
                  <div class="tTitle">Presente Semana:</div>
                  <div class="tTitle">Acumula. Mensual:</div>
                </div>
                <div class="p-col-fixed inside" style="width:33%">
                  <div class="tContent">{{ formatMoney(liquidationData.accumulate_reserve.old) }}</div>
                  <div class="tContent">{{ formatMoney(liquidationData.accumulate_reserve.present) }}</div>
                  <div class="tContent">{{ formatMoney(liquidationData.accumulate_reserve.total) }}</div>
                </div>
              </div>
            </div>

            <div class="p-col">
              <div class="p-grid">
                <div class="p-col inside">
                  <div class="tTitle">Total:</div>
                  <div class="tTitle">Otros valores:</div>
                  <div class="tTitle">Devengado:</div>
                  <div class="tTitle">Mas otros Creditos:</div>
                  <div class="tTitle">Menos otros Debitos:</div>
                  <div class="tTitle">Neto a Pagar:</div>
                  <div class="tTitle">Saldo a pagar:</div>
                </div>
                <div class="p-col-fixed inside" style="width:33%">
                  <div class="tContent">{{ formatMoney(liquidationData.totals_table.total) }}</div>
                  <div class="tContent">{{ formatMoney(liquidationData.totals_table.others_values) }}</div>
                  <div class="tContent">{{ formatMoney(liquidationData.totals_table.accrued) }}</div>
                  <div class="tContent">{{ formatMoney(liquidationData.totals_table.add_credits) }}</div>
                  <div class="tContent">{{ formatMoney(liquidationData.totals_table.sub_debits) }}</div>
                  <div class="tContent">{{ formatMoney(liquidationData.totals_table.net_pay) }}</div>
                  <div class="tContent">{{ formatMoney(liquidationData.totals_table.balance) }}</div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-if="notAccess">
      <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
      <div class="card-body">
        <p class="card-title">No puedes visualizar el registro</p>
        <Button label="Regresar" class="p-button-danger p-mr-2"
                @click="$router.push('/admin')"/>
      </div>
    </div>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>


</template>
<script>
import bouncer from "../../../../helpers/bouncer";
import service from '../../service/liquidation_validation.service'
import commissionService from '../../service/processedEnrollments.service'
import commissionsService from '../../service/commissions.service'
// import html2pdf from 'html2pdf.js'
import FileSaver from 'file-saver';
// import jsPDF from "jspdf"
import {mapGetters} from "vuex";

// import InputNumber from 'primevue/inputnumber';

export default {
  mixins: [bouncer],
  components: {},
  data() {
    return {
      observationRevert:'',
      viewForm: false,
      loading: false,
      liquidationData: {},
      commissionData: {},
      itemsT: [],
      items: null,
      items2: null,
      items3: null,
      items4: null,
      items5: null,
      inputLiq: null,
      dateSel: null,
      inputLiqDesc: null,
      currentItem: null,
      // currentItem2: null,
      deleteItem: null,
      contractDialog: false,
      commissionsDialog: false,
      pdf: false,
      valDialog: false,
      revertDialog: false,
      fTR: 1,
      listaSel: null,
      listaSelConcepto: null,
      listaSelOtro: null,
      lista: [],
      inputOthers: [],
      listaOtros: [],
      listaConceptos: [],
      info: null,
      disable: false,
      notAccess:false,
    }
  },

  created() {
    if (this.canAccess('contralor_actions')) {
      if (this.$route.params.id) {
        this.loading = true;
        service.get(this.$route.params.id).then(x => {
          this.currentItem = x.data.body[0]
          this.liquidationData = x.data;
          this.breadcrumbCreate(x.data.head.id)
          this.lista = x.data.cb_concepts;
          this.listaOtros = x.data.cb_others
          this.listaConceptos = x.data.cb_loans
          this.items2 = x.data.body[0].childrens
          x.data.head.status === 'Validada' ? this.disable = true : this.disable = false
        }).catch((err) => {
              const message = err.response.data;
              let error = this.$utils.formatError(message);
              this.loading = false;
              this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
              this.notAccess=true
            }
        )
            .finally(() => this.loading = false);

      }

      if (this.$route.params.id) {
        this.inputOthers[1] = this.inputOthers[2] = this.inputOthers[4] = this.inputOthers[5] = this.inputOthers[6] = 0
        this.getData()

      }
    }
  },
  computed: {
    ...mapGetters('bread', ['breadcrumb']),
  },
  methods: {
    actionToList(){
      this.$router.push('/liquidation/validate');
    },
    formatMoney(val){
      return (new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })).format(val);
    },
    breadcrumbCreate(liquidacionId) {
      this.$store.dispatch('bread/setItems', [
        {label: 'Lista de Liquidaciones', url: "/#/liquidation/validate"},
        {label: 'Detalle de Liquidación ' + liquidacionId, url: "/#/liquidation/validate/detail/" + liquidacionId},
      ]);
    },
    getData() {
      this.loading = true;
      service.get(this.$route.params.id).then(x => {
        this.items = x.data.body;
        this.items3 = x.data.tb_liquidations_concepts
        this.liquidationData = x.data;
        this.items4 = x.data.tb_other_values
        this.items5 = x.data.tb_loans
        this.viewForm = true
        // eslint-disable-next-line no-unused-vars
      }).finally(() => this.loading = false);
    },
    selectData() {
      this.fTR = 2
      this.loading = true
      this.items2 = this.currentItem.childrens
      this.loading = false
    },
    openContract(id) {
      this.contractDialog = true
      commissionService.get(id).then(x => {
        this.commissionData = x.data;
      }).finally(() => this.loading = false);
    },
    openCommissions(id) {
      this.itemsT = []
      this.commissionsDialog = true
      commissionsService.get(id).then(x => {
        this.itemsT = x.data?.commissions;
      }).catch((err) => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});

      }).finally(() => this.loading = false);
    },
     getPdf() {
      // this.pdf = true
      service.getPDF(this.$route.params.id).then(x => {
        this.info = x.data;
        // this.generatePDF(x.data)

        var byteCharacters = atob(x.data);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], {type: "application/pdf"});

        FileSaver.saveAs(blob, "Natura English Liquidación " + this.liquidationData.head.id + ".pdf");
      })


    },
    changeStatus() {
      var msg
      this.valDialog = false
      msg = 'Liquidación Validada'
      this.liquidationData.head.status = 'Validada'

      service.changeStatus(this.$route.params.id).then(() => {
        this.getData()
        this.$toast.add({severity: 'success', summary: 'Éxito', detail: msg, life: this.$utils.toastLifeTime()});
        this.$nextTick(() => {
          this.$emit('closeSidebar', false)
        })
      }).catch((err) => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      });
    },
    revertStatus() {
      this.liquidationData.head.status = 'Nuevo'

      service.revertStatus(this.$route.params.id,this.observationRevert).then(() => {
        this.getData()
        this.$toast.add({severity: 'success', summary: 'Éxito', detail: 'Liquidación Reversada', life: this.$utils.toastLifeTime()});
        this.$nextTick(() => {
          this.$emit('closeSidebar', false)
        })
        this.revertDialog = false
        this.$router.push('/liquidation/validate');
      }).catch((err) => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      });
    }
  },
}
</script>
<style lang="scss" scoped>
.p-progressbar {
  height: 1px;
}

.hide {
  display: none;
}

.p-field {
  display: inline-flex;
}

.p-unique {
  margin-top: 1rem;
}

.p-toolbar {
  margin-top: 10px;
  background-color: white;
}

.p-toolbar-group-right, .valButtons, .full {
  width: 100%;
}

.full {
  text-align: center;
  margin: 0 0.15rem;

  .p-col {
    margin: 1rem;

    .inside {
      padding: 0;
      margin: 2rem 0 0;
    }
  }
}

.p-float-label label {
  margin-left: .5rem;
}

.p-datatable {
  border-left: 1px solid #e9ecef;
  border-right: 1px solid #e9ecef;

  &-header {
    .p-toolbar {
      background: none;
      border: none;
    }
  }
}

.table-fix {
  .p-datatable-footer {
    padding: 0;
  }

  .p-inputnumber-input {
    width: 100% !important;
  }
}

.ib {
  border: 1px solid #2196F3;;
}

.valButtons {
  margin: 2rem 0;

  .p-button {
    float: right;
  }
}

.df, .dff {
  height: 100%;
}

.p-dropdown .p-dropdown-label, .p-dropdown .p-dropdown-label.p-placeholder {
  padding: 0.5rem !important;
}

.tFooter {
  width: 100%;
  display: flex;

  td {
    width: 16.7%;
    padding: 1rem;

    div
      // , span, input
    {
      width: auto;
    }

    // &:last-child{
    //     width: 2.5%;
    //     // padding-left: 2.75rem;
    // }
  }
}

.fTR {
  background: #E3F2FD !important;
}

.tTitle, .tContent {
  border: 1px solid #e9ecef;
  padding: 1rem;
}

.tTitle {
  text-align: left;
  background-color: #f8f9fa;
}

.p-inputtext {
  width: 75%;
}

.p-datatable-emptymessage {
  display: none !important;
}

.fix {
  width: 1.75rem;
  height: 1.75rem !important;
  background: transparent;
  border: transparent;

  .pi::before {
    font-size: 1.15rem;
    color: #000 !important;
  }
}

.p-breadcrumb {
  margin: 1.6rem auto;
  padding: 2rem;
}

.p-checkbox {
  cursor: none;
}
</style>
