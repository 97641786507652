import httpService from '../../../helpers/http.service'

export default {
    getAll(params) {
        return httpService.get(`processed/enrollments`, {params})
    },
    get(id = null, params = {}) {
        return httpService.get(`processed/enrollments${id ? '/' + id : '' }`, {params})
    },
    delete(id) {
      return   httpService.delete(`processed/enrollments/${id}`);
    },
    save(params, id = null) {
        if(id) {
            return httpService.put(`processed/enrollments/${id}`, params);

        }
        return httpService.post(`processed/enrollments`, params);
    }
}
